import { type Icon, Icons } from '@garmin-avcloud/avcloud-ui-common/icon';

// --- UI Common Icons

export const BackIcon: Icon = {
  ...Icons.ArrowLeft,
  width: 8,
  height: 14,
};

export const ForwardIcon: Icon = {
  ...Icons.ArrowRight,
  width: 8,
  height: 14,
};

export const BlockArrow: Icon = {
  ...Icons.ArrowBlock,
  height: 16,
  width: 8,
};

export const DropdownIcon: Icon = {
  ...Icons.SelectDropdown,
  width: 14,
  height: 8,
};

export const SearchIcon: Icon = {
  ...Icons.Search,
  width: 18,
  height: 18,
  viewBox: '0 0 18 18',
  fill: 'none',
};

// --- General Icons

export const BookmarkIcon: Icon = {
  width: 20,
  height: 20,
  viewBox: '0 0 24 24',
  path: '/assets/icons/general/bookmark.svg#bookmark',
  fill: 'none',
};

export const Calculator: Icon = {
  width: 16,
  height: 16,
  viewBox: '0 0 16 16',
  path: '/assets/icons/general/calculator.svg#calculator',
  fill: 'none',
};

export const CrosshairIcon: Icon = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32',
  path: '/assets/icons/general/crosshair.svg#crosshair',
  fill: 'none',
};

export const GridViewIcon: Icon = {
  width: 20,
  height: 20,
  viewBox: '0 0 24 24',
  path: '/assets/icons/general/grid_view.svg#grid_view',
  fill: 'none',
};

export const ListViewIcon: Icon = {
  width: 20,
  height: 20,
  viewBox: '0 0 24 24',
  path: '/assets/icons/general/list_view.svg#list_view',
  fill: 'none',
};

export const CautionIcon: Icon = {
  width: 20,
  height: 20,
  viewBox: '0 0 16 16',
  path: '/assets/icons/general/caution.svg#caution',
  fill: 'none',
};

export const CheckCircle: Icon = {
  width: 16,
  height: 16,
  viewBox: '0 0 14 14',
  path: '/assets/checkCircle.svg#checkCircle',
  fill: 'none',
};

export const MinusCircle: Icon = {
  width: 16,
  height: 16,
  viewBox: '0 0 20 20',
  path: '/assets/minusCircle.svg#minusCircle',
  fill: 'none',
};

export const PaperIcon: Icon = {
  width: 20,
  height: 20,
  viewBox: '0 0 24 24',
  path: '/assets/icons/general/paper.svg#paper',
  fill: 'none',
};

// --- Flight Planning Icons

export const ExchangeIcon: Icon = {
  width: 20,
  height: 16,
  viewBox: '0 0 20 16',
  path: '/assets/icons/flight-planning/exchange.svg#exchange',
  fill: 'none',
};

export const SuggestIcon: Icon = {
  width: 16,
  height: 20,
  viewBox: '0 0 18 16',
  path: '/assets/icons/flight-planning/suggest.svg#suggest',
  fill: 'none',
};

export const TakeoffIcon: Icon = {
  width: 17,
  height: 17,
  viewBox: '0 0 17 17',
  path: '/assets/flight/weight-and-balance/takeoff.svg#takeoff',
  fill: 'none',
};
export const LandingIcon: Icon = {
  width: 17,
  height: 17,
  viewBox: '0 0 17 17',
  path: '/assets/flight/weight-and-balance/landing.svg#landing',
  fill: 'none',
};

export const ZeroFuelIcon: Icon = {
  width: 17,
  height: 17,
  viewBox: '0 0 17 17',
  path: '/assets/flight/weight-and-balance/zero-fuel.svg#zero-fuel',
  fill: 'none',
};

export const SeatIcon: Icon = {
  width: 70,
  height: 70,
  viewBox: '0 0 70 70',
  path: '/assets/flight/weight-and-balance/seat.svg#seat',
  fill: 'none',
};

// --- Aircraft Icons

export const BookmarkFilledIcon: Icon = {
  width: 20,
  height: 20,
  viewBox: '0 0 24 24',
  path: '/assets/icons/general/bookmark-filled.svg#bookmark',
  fill: 'none',
};

export const ExpandAllIcon: Icon = {
  width: 20,
  height: 20,
  viewBox: '0 0 26 26',
  path: '/assets/icons/general/expand-all.svg#expand-all',
  fill: 'none',
};

export const CollapseAllIcon: Icon = {
  width: 20,
  height: 20,
  viewBox: '0 0 24 24',
  path: '/assets/icons/general/collapse-all.svg#collapse-all',
  fill: 'none',
};

export const BusinessJet: Icon = {
  width: 101,
  height: 23,
  viewBox: '0 0 101 23',
  path: '/assets/aircraft/business_jet.svg#business_jet',
  fill: 'none',
};

export const CommercialJet: Icon = {
  width: 100,
  height: 35,
  viewBox: '0 0 100 35',
  path: '/assets/aircraft/commercial_jet.svg#commercial_jet',
  fill: 'none',
};

export const GarminDefaultAircraft: Icon = {
  width: 76,
  height: 75,
  viewBox: '0 0 76 75',
  path: '/assets/aircraft/Garmin_default_aircraft.svg#Garmin_default_aircraft',
  fill: 'none',
};

export const HighWingPistonGear: Icon = {
  width: 101,
  height: 41,
  viewBox: '0 0 101 41',
  path: '/assets/aircraft/high_wing_piston_gear.svg#high_wing_piston_gear',
  fill: 'none',
};

export const HighWingPiston: Icon = {
  width: 101,
  height: 41,
  viewBox: '0 0 101 41',
  path: '/assets/aircraft/high_wing_piston.svg#high_wing_piston',
  fill: 'none',
};

export const LowWingPistonGear: Icon = {
  width: 101,
  height: 37,
  viewBox: '0 0 101 37',
  path: '/assets/aircraft/low_wing_piston_gear.svg#low_wing_piston_gear',
  fill: 'none',
};

export const LowWingPiston: Icon = {
  width: 101,
  height: 31,
  viewBox: '0 0 101 31',
  path: '/assets/aircraft/low_wing_piston.svg#low_wing_piston',
  fill: 'none',
};

export const Rotorcraft: Icon = {
  width: 101,
  height: 41,
  viewBox: '0 0 101 41',
  path: '/assets/aircraft/low_wing_piston.svg#low_wing_piston',
  fill: 'none',
};

export const TwinPiston: Icon = {
  width: 101,
  height: 41,
  viewBox: '0 0 101 41',
  path: '/assets/aircraft/twin_piston.svg#twin_piston',
  fill: 'none',
};

export const TwinPropCommercial: Icon = {
  width: 101,
  height: 29,
  viewBox: '0 0 101 29',
  path: '/assets/aircraft/twin_prop_commercial.svg#twin_prop_commercial',
  fill: 'none',
};

// Flight Icons

export const WbAircraft: Icon = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  path: '/assets/flight/weight-and-balance/aircraft.svg#aircraft',
  fill: 'none',
};

// Sidebar Icons

export const Maps: Icon = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  path: '/assets/sidebar/map.svg#map',
  fill: 'none',
};

export const Flights: Icon = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  path: '/assets/sidebar/flights.svg#flights',
  fill: 'none',
};

export const Airports: Icon = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  path: '/assets/sidebar/airport.svg#airport',
  fill: 'none',
};

export const Aircraft: Icon = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  path: '/assets/sidebar/aircraft.svg#aircraft',
  fill: 'none',
};

export const Settings: Icon = {
  width: 24,
  height: 25,
  viewBox: '0 0 24 25',
  path: '/assets/sidebar/settings.svg#settings',
  fill: 'none',
};

// --- Airport Icons
export const WindIndicator: Icon = {
  width: 18,
  height: 17,
  viewBox: '0 0 21 21',
  path: '/assets/airport/runway/wind-indicator.svg#wind-indicator',
  fill: 'none',
};
